import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FileUpload from './components/FileUpload'
import Settings from './components/Settings';
import Result from './components/Result';
import Background from './assets/wallpaper.jpg';
import Logo from './assets/logo.png'

const useStyles = makeStyles((theme) => ({
  canvas: {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    minHeight: '100vh'
  },
  appBar: {
    position: 'relative',
    background: 'rgba(18,140,126,0.75)',
    backdropFilter: 'blur(5px)'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['File upload', 'Settings', 'Result'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <FileUpload />;
    case 1:
      return <Settings />;
    case 2:
      return <Result />;
    default:
      throw new Error('Unknown step');
  }
}

function App() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className={classes.canvas}>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar style={{ margin: '-0.25em 0' }}>
          <img src={Logo} alt="website logo" height="40rem" style={{ marginRight: '1rem' }} />
          <Typography variant="h6" style={{ color: 'white' }} noWrap>
            WhatsApp Analyzer
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Analyze word frequencies
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              {activeStep !== steps.length - 1 && (
                <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                  {activeStep !== 1 ? 'Next' : 'Analyze'}
                </Button>
              )}
            </div>
          </React.Fragment>
        </Paper>
        <Typography variant="body2" align="center" style={{ paddingBottom: '1em', color: 'white' }}>
          {'© '}
          {new Date().getFullYear()}
          {' '}
          WhatsApp Analyzer
        </Typography>
      </main>
    </div>
  );
}

export default App;
