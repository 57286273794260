import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import { Box, Button, Dialog, DialogContent, LinearProgress, Snackbar } from '@material-ui/core';
import MovieIcon from '@material-ui/icons/Movie';
import MuiAlert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import Tutorial from '../assets/tutorial.mp4'

export default function FileUpload() {
  const filename = localStorage.getItem('filename');
  const [progress, setProgress] = React.useState(localStorage.getItem('chats') ? 100 : 0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  const handleFileUpload = files => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setProgress(10);
          setTimeout(() => {
            processHTML(reader.result, files[0].name, setProgress, setSnackbar);
          }, 500);
        }
      }
      reader.readAsText(files[0]);
    }
    else {
      localStorage.clear();
      setProgress(0);
    }
  }

  const vertical = 'bottom';
  const horizontal = 'left';
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        This tool was created to analyze the word frequencies in a desired WhatsApp chat.
        Simply upload the HTML file of the chat and enter the words to be analyzed.
        Instructions for creating the HTML file can be found here:
      </Typography>
      <Button variant="outlined" color="secondary" startIcon={<MovieIcon />} style={{ marginBottom: '2em' }} onClick={handleDialogOpen}>
        Tutorial
      </Button>
      <Typography variant="h6" gutterBottom>
        Upload HTML file
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropzoneArea
            initialFiles={filename ? [new File([''], filename, { type: 'text/html', })] : undefined}
            maxFileSize={50000000}
            filesLimit={1}
            acceptedFiles={['.html']}
            showFileNames={true}
            onChange={(files) => handleFileUpload(files)}
          />
        </Grid>
        <Grid item xs={12}>
          <LinearProgressWithLabel value={progress} />
        </Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={snackbar} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" style={{ padding: '0.75em' }}>
          The selected HTML file is in the wrong format!
        </Alert>
      </Snackbar>
      <Dialog maxWidth="md" fullScreen={fullScreen} open={dialog} onClose={handleDialogClose}>
        <DialogContent>
          <video width="100%" src={Tutorial} autoplay controls>
            Your browser doesn't support embedded videos.
          </video>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function processHTML(htmlString, filename, setProgress, setSnackbar) {
  const html = parse(htmlString);
  setProgress(70)
  if (html.props) {
    try {
      let chats = [];
      const htmlNode1 = html.props.children[1]
        .props.children[1]
        .props.children
        .props.children[5]
        .props.children[3]
        .props.children
        .props.children[4]
        .props.children
        .props.children[2]
        .props.children;
      const messages = htmlNode1[htmlNode1.length - 1].props.children;
      console.log(messages);
      setProgress(80);
      messages.forEach(element => {
        const htmlNode2 = element
          .props?.children[1]
          ?.props?.children;
        const htmlNode3 = htmlNode2 && htmlNode2[htmlNode2.length - 1]?.props.children;
        const node = htmlNode3 && htmlNode3[1]
          ?.props?.children[0]
          ?.props;
        if (node) {
          const values = Object.values(node)
          if (values && values.length > 1 && values[1] && typeof values[1] === 'string' && values[1].startsWith('[')) {
            const objects = values[1].split('] ');
            chats.push({
              datetime: objects.length > 1 && objects[0].substring(1),
              from: objects.length > 1 && objects[1].substring(0, objects[1].length - 2),
              message: node.children
                ?.props?.children[0]
                ?.props?.children
                ?.props?.children
                .toString()
                .replace(/\[object Object]/g, '')
                .replace(/(\r\n|\n|\r)/gm, '')
                .replace(/\s+/g, ' ')
            });
          }
        }
      });
      setProgress(90);
      localStorage.setItem('filename', filename);
      localStorage.setItem('chats', JSON.stringify(chats));
      setProgress(100);
    }
    catch (error) {
      console.log(error);
      localStorage.clear();
      setProgress(101);
      setSnackbar(true);
    }
  }
}

function LinearProgressWithLabel(props) {
  const progressStatus = {
    0: 'Waiting for file',
    10: 'Processing HTML file',
    70: 'Searching chat node',
    80: 'Generating message data',
    90: 'Saving message data',
    100: 'Processing finished',
    101: 'Processing failed'
  }
  return (
    <Box>
      <Box display='flex'>
        <Box flexGrow={1}>
          <Typography variant="body2" color="textSecondary">
            {`Status: ${progressStatus[props.value]}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            {`${props.value > 100 ? 0 : props.value}%`}
          </Typography>
        </Box>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.value > 100 ? 0 : props.value} />
      </Box>
    </Box>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}