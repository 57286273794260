import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, LinearProgress, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bar: {
    height: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  }
}));

export default function Result() {
  const classes = useStyles();
  const stats = getStats();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Frequency statistics
      </Typography>
      <List>
        {stats.map((stat, index) =>
          <ListItem key={index}>
            <Grid item xs={3}>
              <Typography>{stat.word}</Typography>
            </Grid>
            <Grid item xs={8}>
              <LinearProgress
                variant="determinate"
                value={stat.count === 0 ? 0 : stat.count * (100 / stats[0].count)}
                className={classes.bar}
              />
            </Grid>
            <Grid item xs={1}>
              <Grid container justify="flex-end">
                <Typography>{stat.count}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
}

function getStats() {
  const words = JSON.parse(localStorage.getItem('words'));
  const chats = JSON.parse(localStorage.getItem('chats'));
  const user = localStorage.getItem('user');
  const caseSensitive = JSON.parse(localStorage.getItem('case'));
  let stats = []
  if (words) words.forEach(element => {
    let counter = 0;
    if (chats) chats.forEach(chat => {
      if ((!user || user === chat.from) && chat.message) {
        chat.message.split(' ').forEach(string => {
          if (caseSensitive) {
            if (string === element) counter++;
          }
          else {
            if (string.toUpperCase() === element.toUpperCase()) counter ++;
          }
        });
      }
    });
    stats.push({
      word: element,
      count: counter
    });
  });
  stats.sort((a, b) => {
    return b.count - a.count;
  });
  return stats;
}