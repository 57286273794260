import * as React from 'react';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';

export default function Settings() {
  const forceUpdate = useForceUpdate();
  const dropdownValues = getDropdownValues();
  const words = JSON.parse(localStorage.getItem('words'));
  const user = localStorage.getItem('user');
  const caseSensitive = JSON.parse(localStorage.getItem('case'));
  const [inputWord, setInputWord] = React.useState('');
  const [dropdownValue, setDropdownValue] = React.useState(
    user ? Object.keys(dropdownValues).find(key => dropdownValues[key] === user) : 0
  );
  const [caseSensitivity, setCaseSensitivity] = React.useState(
    caseSensitive ? caseSensitive : false
  );

  const handleDropdownChange = e => {
    const selectedValue = e.target.value;
    setDropdownValue(selectedValue);
    if (selectedValue === 0)
      localStorage.removeItem('user');
    else
      localStorage.setItem('user', dropdownValues[selectedValue]);
  }

  const handleCheckboxChange = e => {
    const checked = e.target.checked;
    setCaseSensitivity(checked);
    localStorage.setItem('case', checked);
  }

  const handleInputChange = e => {
    setInputWord(e.target.value);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleAddWord();
    }
  };

  const handleAddWord = () => {
    let words = JSON.parse(localStorage.getItem('words'));
    if (!words) words = [];
    words.push(inputWord);
    localStorage.setItem('words', JSON.stringify(words));
    setInputWord('');
    forceUpdate();
  };

  const handleRemoveWord = index => {
    let words = JSON.parse(localStorage.getItem('words'));
    if (words) {
      words.splice(index, 1);
      localStorage.setItem('words', JSON.stringify(words));
      forceUpdate();
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Set user to analyze
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: '1em' }}>
        <Grid item md={6} xs={12}>
          <Select
            fullWidth
            variant="outlined"
            value={dropdownValue}
            onChange={handleDropdownChange}
            style={{ height: '2.5em' }}
          >
            {Object.values(dropdownValues).map((value, index) =>
              <MenuItem key={index} value={index}>
                {value}
              </MenuItem>
            )}
          </Select>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={caseSensitivity}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Case sensitive search"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Add words to analyze
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={10} xs={12}>
          <TextField
            value={inputWord}
            label="Word for analysis"
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange}
            onKeyUp={handleKeyUp}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            variant="contained"
            color="default"
            disabled={inputWord ? false : true}
            fullWidth onClick={handleAddWord}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12}>
          <List>
            {words && words.map((word, index) =>
              <ListItem key={index}>
                <ListItemText primary={word} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleRemoveWord(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function getDropdownValues() {
  let values = { 0: 'All users' };
  const chats = JSON.parse(localStorage.getItem('chats'));
  if (chats) {
    chats.forEach((chat, index) => {
      if (!Object.values(values).includes(chat.from)) {
        values[index + 1] = chat.from;
      }
    });
  }
  return values;
}

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue(value => ++value);
}